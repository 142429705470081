import React, { useState } from 'react';

function MySvyatogorsk(props) {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [message, setMessage] = useState('');

 
const closeMySvyatogorsk= () =>{
  props.setShowMySvyatogorsk(false)
}

  return (
  
    <div className='feedback__wrapper' style={{ display: props.showMySvyatogorsk  ? 'block' : 'none' }}>
     
    <div className='feedback__container'>
    <button  className="feedback__close-button" onClick={closeMySvyatogorsk}>
                            &times;
      </button>
      <div className='mysvyatogorsk__content'>

{/* <h1 style={{fontFamily: 'Kingthings_Petrock', letterSpacing: "1", textAlign: "center", marginBottom: "12px"}}>Святогорск - город моей мечты, любви и вдохновения!</h1> */}
<h1 style={{fontFamily: 'Kingthings_Petrock'}}>"Любите ли Вы Святогорск  так, как люблю его я?"</h1>
<h2 style={{fontFamily: 'Kingthings_Petrock'}}> я знаю точно, что многие коренные "баняне" не любят Святогорск так, как некоторые приезжие.</h2><br/>
<p>Потому что местные жители воспринимают все вокруг как должное и обыденное, не замечая ту красоту и необычайность, которую видят люди посещая эти прекрасные  места. Многие конечно просто повосхищаются и забудут, а некоторым увиденное западет в душу!</p>
<p>Я полюбил этот город в раннем детстве, когда родители привозили меня к маминой сестре в гости.</p>
<p>В основном это было летом, когда Святогорск превращался в один сплошной туристический лагерь или огромную базу отдыха в которой отдыхающие делились на два потока: одни шли на пляж, другие шли с пляжа, в большинстве своем мужчины были в плавках, женщины соответственно в купальниках что и создавало то неповторимое ощущение единого заведения для отдыха!</p> 
<p>Картину дополняли отряды "короедов", как их называл мой двоюродный брат, это были отряды пионеров с пионерских лагерей, которых тут было невообразимое количество.</p>
<p>В начале и в конце строя шли пионервожатые, возрастом не сильно старше своих подопечных и переодически выкрикивали речовки типа "Всем, всем", а детвора весело и с энтузиазмом подхватывала "Добрый день!!!", и много еще прикольного которое я уже забыл.</p> 
<p>В те далёкие времена на Северском донце располагались пляжи санаториев, пансионатов, лечебниц и тд, где вход был по санаторным книжкам или что то типа того.</p> 
<p>Берег озера "Банное" со стороны Донца служил  пляжами для пионерских лагерей и других детских учреждений. Мне хорошо запомнились как они проводили "День Нептуна", в каждом он был в свой день и по своей программе, но всегда это было очень зрелищно и интересно!</p>
<p>Наряжали кого то из взрослых мужчин Нептуном, еще были водяные, бабки ёжки, чертятами была детвора которая полностью намазывалась грязью из озера и конечно же была Русалочка, которую воровали злые силы, и её подруги и друзья которые её освобождали! Было очень зрелищно!</p>
<img src='/img/project/neptun.jpeg' style={{maxHeight: "100vh", width: "90%", margin: "0 auto"}} />
<p>В те времена действительно работал лозунг: "Всё лучшее детям!"</p>
<p>Местные, вернее их гости, святогорцам не до отдыха было, купались с детворой на берегу озера со стороны города. На пляже было много больших деревьев, под кроной которых можно было загорать хоть целый день, что мы и делали. </p>
<p>Иногда брали с собой удочки и между купаниями ходили в камыши ловить силявку, так мы называли уклейку.</p>
<p>На городской пляж ходили через улицу где расположен был горисполком, а напротив был магазин из которого каждое утро вывозили тележку с вкуснейшим  мороженным! Часто были в бумажных стаканчиках, из которых кушали деревянной палочкой.</p>
<p>Еще несгладимый след оставили автоматы газводы! Вкуснейший напиток с сиропом за 3 копейки! Я целый год собирал эти монеты чтоб потом в Святогорске напиться вдоволь этой живительной влаги!</p>
<p>А вечером наступало время дискотек, танцев, и различных шоу!</p>
<p>Со всех сторон доносился детский крик, смех, аплодисменты успешным артистам!</p>
<p>С наступлением сумерек взрослые отдыхающие, красиво одетые и надушинные советскими духами, не спеша подтягивались к танцплощадкам санаториев и баз отдыха, где уже вовсю   звучала советская и зарубежная эстрада.</p>
<p>Эту атмосферу всеобщего какого то блаженства, спокойствия, радости и умиротворения тяжело описать словами!!!</p>
<p>Если кто то хочет поделится своими воспоминаниями об отдыхе в прекрасном Святогорске, напишите свои контакты  <a href="/disqus.html"> здесь</a> и мы свяжемся с вами! Или напишите сообщение на электронную почту <a href="mailto:ytcenko1970@gmail.com">ytcenko1970@gmail.com</a></p>


               
        
    </div> 
   

    </div>
  
    </div>
    
  );
}

export default MySvyatogorsk;
