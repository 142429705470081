import React, { useState } from 'react';

function RealitiesSvyatogorsk(props) {
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [message, setMessage] = useState('');

 
const closeRealitiesSvyatogorsk= () =>{
  props.setShowRealitiesSvyatogorsk(false)
}

  return (
  
    <div className='feedback__wrapper' style={{ display: props.showRealitiesSvyatogorsk  ? 'block' : 'none' }}>
     
    <div className='feedback__container'>
    <button  className="feedback__close-button" onClick={closeRealitiesSvyatogorsk}>
                            &times;
      </button>
      <div className='mysvyatogorsk__content'>

<h1 style={{fontFamily: 'Kingthings_Petrock', letterSpacing: "1", textAlign: "center", marginBottom: "15px"}}>Святогорск - город моей мечты, любви и вдохновения!</h1>
<h2 style={{fontFamily: 'Kingthings_Petrock'}}>"Донецкая Швейцария!"(А.П. Чехов)</h2> 
  <h2 style={{fontFamily: 'Kingthings_Petrock'}}>"Жемчужина Донбасса"</h2><br/>
<p>Святогорск - это укальный город! В нём уникально всё, история, климат, природа!!</p>. <p>История города покрыта таинственной загадкой и связана с монастырём, основанного неизвестно кем ранее XIII века, хотя официальная дата основания поселения 1571 год!</p>
<p>В ней было всё: и крымские татары, и казаки, Екатерина II и куда же без графа Потёмкина, и Николая I, и даже  Антона Павловича Чехова! </p>
<p>Природа по своей уникальности не успупает истории! Меловые горы, на которых сохранились редкие растения древности, например, сосна меловая, сохранившаяся с доледникового периода.</p>
<p>Река Северский Донец, разделяющая город, считается одной из самых живописных рек Восточно-Европейской равнины.</p>
<p> Здесь есть и горы, и река, и лес. Эти природные ресурсы способствуют созданию уникального климата. Меловые горы защищают от холодных ветров. И летом здесь не так жарко благодаря Донцу. Сосновые леса насыщают ионизированный воздух фитонцидами. Поэтому в Святогорске успешно лечатся заболевания органов дыхания, кровообращения, нервной системы и др.</p>
<p>Это был город в котором буквально всё просило тебя забыть о проблемах и окунуться в незабываемую отмосферу беззаботного отдыха и наслаждения природой!</p>
<p>Но, к сожалению, все это перестало иметь значение с 24 февраля 2022 года....</p> 
<p>На сегодняшний день война продолжается, большинство заведений для отдыха разрушено как и большинство городов Донбасса и уже ни о каком отдыхе мысли не возникают.</p> 
<p>Радует, что город постепенно оживает: постоянно открываются новые магазины и учереждения по оказанию услуг.</p> 
<p></p> 
<p></p> 
<p>Если кто то хочет поделится своими воспоминаниями об отдыхе в прекрасном Святогорске, напишите свои контакты  <a href="/disqus.html"> здесь</a> и мы свяжемся с вами! Или напишите сообщение на электронную почту <a href="mailto:ytcenko1970@gmail.com">ytcenko1970@gmail.com</a></p>


               
        
    </div> 
   

    </div>
  
    </div>
    
  );
}

export default RealitiesSvyatogorsk;
